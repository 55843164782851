import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {IntroComponent} from "./unit01/intro/intro.component";
import {ListenerComponent} from "./unit01/observer-pattern/listener.component";
import {SimpleEventListenerComponent} from "./unit01/simple-event-listener/simple-event-listener.component";
import {FullEventListenerComponent} from "./unit01/full-event-listener/full-event-listener.component";
import {ContentsComponent} from "./contents/contents.component";
import {ButtonClickerComponent} from "./unit01/full-event-listener/button-clicker.component";


const routes: Routes = [
  { path:'intro', component:IntroComponent},
  {path:'listener', component:ListenerComponent},
  {path:'simple-dom-event-listener', component:SimpleEventListenerComponent},
  {path:'full-dom-event-listener', component:FullEventListenerComponent},
  {path:'contents', component:ContentsComponent},
  {path:'test', component:ButtonClickerComponent},

  { path:'', redirectTo:'/intro', pathMatch:'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
