import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import * as codeMd from 'raw-loader!./listener.md';
import {fromEvent} from "rxjs";

@Component({
  selector: 'app-simple-event-listener',
  templateUrl: './simple-event-listener.component.html',
  styles: []
})
export class SimpleEventListenerComponent implements OnInit, AfterViewInit{

  code:string = codeMd;
  clickCount = 0;

  @ViewChild('button', {static:false, read:ElementRef}) button;
  @ViewChild('console', {static:false, read:ElementRef}) console;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
      fromEvent(this.button.nativeElement,'click').subscribe(
        (event) => this.console.nativeElement.innerText = `Clicked ${++this.clickCount}`
      )
  }

}
