import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MarkdownModule} from "ngx-markdown";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {IntroComponent} from './unit01/intro/intro.component';
import {ListenerComponent} from './unit01/observer-pattern/listener.component';
import {CardComponent} from './core/card/card.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {materialModules} from "./material-modules";
import {LinkButtonComponent} from './core/link-button/link-button.component';
import {GistComponent} from './core/gist/gist.component';
import {ButtonClickerComponent} from './unit01/full-event-listener/button-clicker.component';
import {RxjsVisualizeMarbleModule} from "./core/visualize/rxjs-visualize-marble.module";
import { SimpleEventListenerComponent } from './unit01/simple-event-listener/simple-event-listener.component';
import { FullEventListenerComponent } from './unit01/full-event-listener/full-event-listener.component';
import { ContentsComponent } from './contents/contents.component';

@NgModule({
  declarations: [
    AppComponent,
    IntroComponent,
    ListenerComponent,
    CardComponent,
    LinkButtonComponent,
    GistComponent,
    ButtonClickerComponent,
    SimpleEventListenerComponent,
    FullEventListenerComponent,
    ContentsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MarkdownModule.forRoot({loader:HttpClient}),
    BrowserAnimationsModule,
    ...materialModules,
    RxjsVisualizeMarbleModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
