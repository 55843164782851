import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import {fromEvent, Observable, Subject, Subscription} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-button-clicker',
  templateUrl: './button-clicker.component.html',
  styles: [
    '.wrapper {background-color:#eee}',
    '.bar {margin-top:25px;background-color:#eeeeee;padding:10px;border-radius:3px}',
    '.bar span, .bar button { margin-right:10px}',
    '.status {color:green; font-style:italic}'
  ]
})
export class ButtonClickerComponent implements OnInit {

  @ViewChild('subscribeBtn', {static:false, read:ElementRef}) subscribeBtn:ElementRef;
  @ViewChild('unsubscribeBtn', {static:false, read:ElementRef} ) unsubscribeBtn:ElementRef;
  @ViewChild('subscribeStatus', {static:false}) subscribeStatus:ElementRef;
  @ViewChild('eventConsole', {static:false}) eventConsole:ElementRef;
  @ViewChild('clickBtn', {static:false, read:ElementRef}) clickBtn:ElementRef;

  subscription:Subscription;

  subscribeDisabled:boolean = false;

  observable:Subject<any> = new Subject();
  source:Observable<any>;

  clickCount = 0;

  constructor() { }

  ngOnInit() {
  }

  unsubscribe() {
    this.subscription.unsubscribe();
    const subscribeStatus = this.subscribeStatus.nativeElement;
    subscribeStatus.innerText = 'Unsubscribed';
    this.subscribeDisabled = false;
  }

  subscribe() {
    console.log('in subscribe');
      const clickBtn = this.clickBtn.nativeElement;
      const eventConsole = this.eventConsole.nativeElement;
      const subscribeStatus = this.subscribeStatus.nativeElement;
      this.subscribeDisabled = true;

    this.source = fromEvent(clickBtn, 'click').pipe(map( (e:any) => {
      console.log('in map function', e);
      this.clickCount++;
      if(e.fake) {
        eventConsole.innerText = 'terminated';
        throw Error('fake error happened!');
      }
      this.observable.next(this.clickCount);
      return this.clickCount;
    }
      ));

    this.subscription = this.source.subscribe(
      (next) =>  eventConsole.innerText = `Total clicks: ${next}`,
      (error) => eventConsole.innerText = `${error}`,
      () => { subscribeStatus.innerText = 'Completed'}
    );


    subscribeStatus.innerText = "Subscribed";
  }

  fakeError() {
    let clickBtn:any = this.clickBtn.nativeElement;
    let e:any = new Event('click');
    e.fake = true;
    clickBtn.dispatchEvent(e);
  }


}
