import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-gist',
  templateUrl: './gist.component.html',
  styleUrls: ['./gist.component.scss']
})
export class GistComponent implements OnInit {

  @Input() gist: string;
  @ViewChild('iframe', {static: true}) iframe: ElementRef;

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentElement.contentWindow;
    const content = `
        <html>
        <head>
          <base target="_parent">
          <script>
          function resizeIframe(obj) {
            console.log('resize');
    obj.rxjs.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
  }
</script>          
        </head>
        <body onLoad="resizeIframe(this)">
        <script type="text/javascript" src="${this.gist}"></script>
        </body>
      </html>
    `;
    doc.open();
    doc.write(content);
    doc.close();
  }

}
