import { Component, OnInit } from '@angular/core';
import * as codeMd from 'raw-loader!./code.md';

@Component({
  selector: 'app-full-event-listener',
  templateUrl: './full-event-listener.component.html',
  styles: []
})
export class FullEventListenerComponent implements OnInit {

  code:string = codeMd;

  constructor() { }

  ngOnInit() {
  }

}
